import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import Layout from "../components/layout"
import ListItemWithIcon from "../components/ui/list-item-with-icon"
import CheckedIcon from "../svg/arrow-list-icon.svg";
import ContactUsForm from "../components/contact-us-form"

const options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const type = node?.data?.target?.__typename || null

      if (type !== "ContentfulTextGradient") {
        return children
      }

      return <h1 className="text-white big-h1">{node?.data?.target?.content}</h1>
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return <h1 className="text-white">{children}</h1>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p className="text-white">{children}</p>
    },
  },
}

const RequestAccess = ({ location }) => {
  const { data } = useStaticQuery(
    graphql`
    query {
      data: contentfulPageRequestAccess {
        title
        description
        heroContent {
          raw
          references {
            ... on ContentfulTextGradient {
              contentful_id
              __typename
              content
            }
          }
        }
        checkList
      }
    }
    `
  )
  const content = data?.heroContent || ""
  const checkList = data?.checkList || []

  return (
    <Layout location={location}>

      <div className="bg-white">
        <div className="w-full flex flex-wrap">
          <div className="w-full md:w-1/2 relative shadow-2xl">
            <div className="flex flex-col sticky top-0 w-full bg-gradient-to-t from-blue-950 via-blue-950 to-blue-700 md:h-screen">
              <div className="flex flex-col justify-center max-w-xl w-full my-auto mx-auto px-4 md:justify-start md:px-12">
                <div className="py-8">

                  {content && renderRichText(content, options)}

                  {checkList && (
                    <ul className="my-12 list-none relative mx-auto text-left text-white lg:text-xl">
                      {checkList.map(item => (
                        <ListItemWithIcon
                          key={item}
                          text={item}
                          className="pl-10"
                          icon={<CheckedIcon className="list-icon transform -translate-y-1 h-8 w-8" />}
                        />
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2 flex flex-col">
            <div className="flex flex-col justify-center max-w-xl w-full my-auto mx-auto px-4 md:justify-start md:px-12">
              <ContactUsForm
                formName='request-access'
                buttonClassName='request-access-btn'
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RequestAccess
